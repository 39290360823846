import { Component, Injector, Input, NgZone, ViewChild } from '@angular/core'
import { FormControl, FormGroup } from '@angular/forms'
import { ActivatedRoute } from '@angular/router'
import { InputComponent } from '@bh/design-system'
import { LoggerFactory, LoggerService, LoggingConfig } from '@bh/logging'
import {
    AuthenticationService,
    displayBiometricsModal,
    loginRequest,
    selectBiometrics,
    selectBiometricsModal,
    startLoading,
    updateBiometrics
} from '@bh/security'
import { Browser } from '@capacitor/browser'
import { Keyboard } from '@capacitor/keyboard'
import { SplashScreen } from '@capacitor/splash-screen'
import { Preferences } from '@capacitor/preferences'
import { AlertController, IonInput, Platform } from '@ionic/angular'
import { Store } from '@ngrx/store'
import { TranslateService } from '@ngx-translate/core'
import { combineLatest, from, Observable, of } from 'rxjs'
import { catchError, delay, filter, map, mergeMap, take, takeUntil, tap } from 'rxjs/operators'
import packageJson from '../../../../package.json'
import { AppInitService } from '../../../app/src/app/app-init.service'
import { environment } from '../../../app/src/environments/environment'
import {
    Biometrics,
    BiometricService,
    BiometricsType,
    ConsentBannerService,
    dismissNotification,
    StoredCredentials
} from '@events'
import { NativeBiometric } from 'capacitor-native-biometric'
import { ActiveLoaderComponent } from '../../../shared/src/lib/components/active-loader/active-loader.component'
import { selectNetworkConnected } from '@bh/offline'

@Component({
    selector: 'bh-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent extends ActiveLoaderComponent {
    //envPrefixes preprod order should be 'preprod-2' and then 'preprod' if we change this order it will extract the preprod-2 prefix as preprod env
    public static readonly envPrefixes = ['dev', 'train', 'test', 'preprod-2', 'preprod', 'prod']
    @Input() logo = ''
    @ViewChild('passInput', { read: InputComponent }) passwordInput?: InputComponent
    @ViewChild('userInput', { read: InputComponent }) userInput?: InputComponent
    @ViewChild('userNameInput', { static: false }) userNameInput?: IonInput

    public version: string = packageJson.version
    public name: string = packageJson.name
    public authForm: FormGroup
    public backdropImage = ''
    public isKeyboardVisible = false

    public id$: Observable<void>
    private readonly platform: Platform
    private readonly translateService: TranslateService
    private readonly logger: LoggerService

    public showBiometricsModal$ = this.store.select(selectBiometricsModal)
    public biometricInfo$ = this.store.select(selectBiometrics)

    public showBiometricButton$ = this.biometricInfo$.pipe(
        mergeMap((biometricsInfo) =>
            from(Preferences.get({ key: AuthenticationService.STORAGE_PASS })).pipe(
                map((password) => ({
                    biometricsInfo,
                    password
                }))
            )
        ),
        map(
            ({ biometricsInfo, password }) =>
                biometricsInfo.isAvailable && !(biometricsInfo.isEnabled && password.value === null)
        )
    )

    public storedCredentials = <StoredCredentials>{}
    public isPasswordStored = false
    public biometricInfo: Biometrics = {
        isAvailable: false,
        isEnabled: false,
        biometryId: 0,
        biometryType: '',
        notifyUser: false,
        username: '',
        showModal: false,
        biometryImagePath: ''
    }

    public reAnimate = true
    constructor(
        protected store: Store,
        private readonly alertController: AlertController,
        private readonly route: ActivatedRoute,
        private readonly loggerFactory: LoggerFactory,
        private readonly authenticationService: AuthenticationService,
        private readonly injector: Injector,
        private readonly biometricService: BiometricService,
        private readonly activatedRoute: ActivatedRoute,
        private readonly ngZone: NgZone,
        private readonly consentBannerService: ConsentBannerService
    ) {
        super()
        SplashScreen.hide()
        this.platform = injector.get<Platform>(Platform)
        this.translateService = injector.get<TranslateService>(TranslateService)
        this.logger = this.loggerFactory.getLogger('LoginComponent')

        this.authForm = new FormGroup({
            user: new FormControl(''),
            pass: new FormControl('')
        })

        this.id$ = this.route.params.pipe(
            takeUntil(this.destroy$),
            map(() => {
                this.authForm.get('user')?.reset()
                this.authForm.get('pass')?.reset()
            })
        )

        this.id$.subscribe()

        this.store
            .select(selectNetworkConnected)
            .pipe(takeUntil(this.destroy$))
            .subscribe((status) => {
                if (status) {
                    this.updateBackdrop()
                } else {
                    this.backdropImage = ''
                }
            })
    }

    ionViewDidEnter(): void {
        this.reAnimate = true
        this.consentBannerService.checkFullStoryStatus()

        this.platform
            .ready()
            .then(() => {
                this.platform.resume.pipe(takeUntil(this.viewDismissed$)).subscribe(() => {
                    this.ngZone.run(() => {
                        this.biometricService.getBiometricsResult()
                    })
                })
            })
            .catch((error) => {
                this.logger.debug('Error calling Biometric Result from background: ' + error)
            })

        if (this.passwordInput) {
            this.passwordInput.resetPasswordState()
        }

        if (this.platform.is('capacitor')) {
            Keyboard.addListener('keyboardWillShow', this.keyboardWillShow)
            Keyboard.addListener('keyboardWillHide', this.keyboardWillHide)

            this.biometricService.getBiometricsResult()

            this.biometricInfo$.pipe().subscribe((info) => (this.biometricInfo = info))
        }
        this.getStoredAuthCredentials()
    }

    public updateBackdrop(): void {
        of(true)
            .pipe(
                delay(2500),
                tap(() => {
                    this.ngZone.run(() => {
                        this.backdropImage = 'assets/transparent.svg'
                    })
                }),
                filter(() => this.activatedRoute.snapshot.queryParams.logout !== true)
            )
            .subscribe(() => {
                this.getStoredCredentials(false)
            })
    }

    ionViewWillLeave(): void {
        this.reAnimate = false
    }

    public async presentAlert(): Promise<void> {
        const btnOk = await this.translateService.get('login.ok').toPromise()
        const strMessage = await this.translateService
            .get('login.credentials-missing-error')
            .toPromise()

        const alert = await this.alertController.create({
            cssClass: '',
            header: '',
            subHeader: '',
            message: strMessage,
            buttons: [btnOk]
        })

        await alert.present()
    }

    async handleUrlOpen(type: string, formGroup?: FormGroup): Promise<void> {
        let url = ''
        const changeEnv =
            type === 'forgotUsername' || type === 'forgotPassword' || type === 'signup'
        if (changeEnv && formGroup !== undefined) {
            await this.setupEnvironment(formGroup)
        }

        switch (type) {
            case 'help':
                url = environment.config().helpUrl
                break
            case 'forgotUsername':
                url = environment.config().forgotUsername
                break
            case 'forgotPassword':
                url = environment.config().forgotPassword
                break
            case 'signup':
                url = environment.config().signUp
                break
            case 'learnMore':
                url = environment.config().learnMore
        }

        Browser.open({ url, presentationStyle: 'popover' })
    }

    private extractEnvironment(username: string): string {
        for (const envPrefix of LoginComponent.envPrefixes) {
            const prefix = envPrefix + '-'
            if (username.toLowerCase().startsWith(prefix)) {
                return envPrefix
            }
        }

        return ''
    }

    public async authenticate(formGroup: FormGroup): Promise<void> {
        const inputUser = await this.userInput?.input?.getInputElement()
        const user = inputUser?.value
        const inputPass = await this.passwordInput?.input?.getInputElement()
        const pass = inputPass?.value

        if (user && pass) {
            this.authForm.setValue({
                user,
                pass
            })
        }

        if (!formGroup.valid) {
            await this.presentAlert()
            return
        }
        await this.authenticationService.clearAuth()

        await this.onSubmit(formGroup)
        this.store.dispatch(startLoading({ action: loginRequest.type }))
        this.store.dispatch(dismissNotification())
    }

    public getPrefix(value: string) {
        let prefix = this.extractEnvironment(value)
        if (prefix === '') {
            // if prefix not specified make sure config matching build environment is loaded
            prefix = environment.buildEnv
        }
        return prefix
    }

    public async updateEnv(prefix: string) {
        const appInit = new AppInitService(this.injector)
        await Preferences.remove({ key: AppInitService.STORAGE_CURRENT_ENV })
        await Preferences.set({ key: AppInitService.STORAGE_CURRENT_ENV, value: prefix })
        await appInit.loadConfig(prefix)
        const logCfg: LoggingConfig = environment.config().logging
        this.loggerFactory.updateLoggingConfig(logCfg)
    }

    public keyboardWillShow = () => this.setKeyboard(true)
    public keyboardWillHide = () => this.setKeyboard(false)

    public async onSubmit(formGroup: FormGroup) {
        await this.setupEnvironment(formGroup)
        this.store.dispatch(loginRequest({ ...formGroup.value }))
    }

    public setKeyboard(status: boolean): void {
        this.isKeyboardVisible = status
    }

    public async setupEnvironment(formGroup: FormGroup): Promise<void> {
        const prefix = this.getPrefix(formGroup.value.user || '')
        if (prefix !== '') {
            formGroup.value.user =
                formGroup.value.user && formGroup.value.user.replace(`${prefix}-`, '')
            await this.updateEnv(prefix)
        }
    }

    public getStoredCredentials(buttonPress: boolean) {
        if (buttonPress && !this.biometricInfo.isEnabled) {
            this.store.dispatch(displayBiometricsModal({ showModal: true }))
            return
        }

        if (!this.biometricInfo.isEnabled) {
            return
        }
        combineLatest([
            from(NativeBiometric.getCredentials({ server: this.name })),
            from(Preferences.get({ key: AuthenticationService.STORAGE_USER })),
            from(Preferences.get({ key: AuthenticationService.STORAGE_PASS })),
            from(Preferences.get({ key: AppInitService.LOGIN_STATUS }))
        ])
            .pipe(
                filter(
                    ([_, storageUser, storagePassword, loginStatus]) =>
                        ((storageUser.value === null && storagePassword.value === null) ||
                            loginStatus.value !== 'true') &&
                        this.biometricInfo.isEnabled
                    //This filter is necessary in order to prevent this function from running when the user is not logged out
                ),
                take(1)
            )
            .subscribe(
                ([biometricsCredentials]) => {
                    const bioCredentials = biometricsCredentials
                    Object.assign(this.storedCredentials, {
                        username: bioCredentials.username,
                        password: bioCredentials.password
                    })
                    this.store.dispatch(
                        updateBiometrics({
                            username: bioCredentials.username,
                            isAvailable: true,
                            isEnabled: true,
                            notifyUser: false,
                            biometryId: this.biometricInfo.biometryId,
                            biometryType: this.biometricInfo.biometryType as BiometricsType,
                            biometryImagePath: this.biometricInfo.biometryImagePath
                        })
                    )

                    if (this.storedCredentials.username) {
                        this.autoLogin()
                    }
                },
                (error) => {
                    this.logger.debug('Failed to get credentials', error)
                }
            )
    }

    private autoLogin(): void {
        from(
            NativeBiometric.verifyIdentity({
                useFallback: true,
                maxAttempts: 3
            })
        )
            .pipe(
                tap(async () => {
                    this.store.dispatch(startLoading({ action: loginRequest.type }))

                    const authUser = this.authForm.get('user')
                    const authPass = this.authForm.get('pass')

                    if (authUser && authPass) {
                        authUser.setValue(this.storedCredentials.username)
                        authPass.setValue(this.storedCredentials.password)
                    }

                    await this.authenticationService.clearAuth()
                    await this.setupEnvironment(this.authForm)
                    this.store.dispatch(loginRequest({ ...this.authForm.value }))
                }),
                catchError((error) => {
                    this.logger.error('Identity verification failed', error.message, error)
                    return of(null)
                })
            )
            .subscribe()
    }

    public getStoredAuthCredentials() {
        combineLatest([
            from(Preferences.get({ key: AuthenticationService.STORAGE_USER })),
            from(Preferences.get({ key: AuthenticationService.STORAGE_PASS })),
            from(Preferences.get({ key: AppInitService.STORAGE_CURRENT_ENV })),
            this.biometricInfo$
        ])
            .pipe(
                takeUntil(this.viewDismissed$),
                filter(
                    ([storageUser, _, currentEnvironment, biometricsInfo]) =>
                        storageUser.value !== null &&
                        currentEnvironment.value !== null &&
                        !biometricsInfo.isEnabled
                )
            )
            .subscribe(([storageUser, storagePassword, currentEnvironment, _]) => {
                const authUser = this.authForm.get('user')
                const authPass = this.authForm.get('pass')
                const envPrefix = currentEnvironment.value
                let user = storageUser.value ?? ''
                if (envPrefix !== '' && envPrefix !== 'prod') {
                    user = envPrefix + '-' + storageUser.value
                }
                const pass = storagePassword.value ?? ''
                this.isPasswordStored = true
                if (authUser && user) {
                    authUser.setValue(user)
                }

                if (authPass && pass) {
                    authPass.setValue(pass)
                }
            })
    }

    onPasswordInputUpdated() {
        this.ngZone.run(() => {
            this.isPasswordStored = false
        })
    }
}
