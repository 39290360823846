import { inject, NgModule } from '@angular/core'
import { ActivatedRouteSnapshot, PreloadAllModules, RouterModule, Routes } from '@angular/router'
import { LoginComponent, ProfileGuard } from '@login'
import { HeaderGuard, NavWrapperComponent, ShellGuard } from '@nav'
import { ParentPersonalizationGuard } from './guards/parent-personalization.guard'
import { RootGuard } from './guards/root.guard'
import { switchMap } from 'rxjs/operators'

const routes: Routes = [
    {
        path: 'login',
        canDeactivate: [() => inject(ProfileGuard).canDeactivate()],
        canActivate: [(route: ActivatedRouteSnapshot) => inject(HeaderGuard).canActivate(route)],
        data: { headerColor: 'blue' },
        component: LoginComponent
    },
    {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full'
    },
    {
        path: 'arrival',
        loadChildren: () => import('./modules/arrival.module').then((m) => m.ArrivalpModule),
        canActivate: [(route: ActivatedRouteSnapshot) => inject(HeaderGuard).canActivate(route)],
        data: { headerColor: 'white' }
    },
    {
        path: 'health-check',
        loadChildren: () =>
            import('./modules/health-check.module').then((m) => m.HealthCheckModule),
        canActivate: [(route: ActivatedRouteSnapshot) => inject(HeaderGuard).canActivate(route)],
        data: { headerColor: 'white' }
    },
    {
        path: 'mark-absence',
        loadChildren: () =>
            import('./modules/mark-absence.module').then((m) => m.MarkAbsenceModule),
        canActivate: [(route: ActivatedRouteSnapshot) => inject(HeaderGuard).canActivate(route)],
        data: { headerColor: 'white' }
    },
    {
        path: 'home/:guardianId',
        component: NavWrapperComponent,
        children: [
            {
                path: 'activity-feed',
                loadChildren: () => import('./modules/home.module').then((m) => m.HomeModule),
                data: {
                    quickActions: true
                }
            },
            {
                path: 'calendar',
                loadChildren: () =>
                    import('./modules/calendar.module').then((m) => m.CalendarModule),
                data: {
                    quickActions: false
                }
            },
            {
                path: 'memories',
                loadChildren: () =>
                    import('./modules/memories.module').then((m) => m.MemoriesModule),
                data: {
                    quickActions: false
                }
            },
            {
                path: 'my-learners',
                loadChildren: () =>
                    import('./modules/my-learners.module').then((m) => m.MyLearnersModule),
                data: {
                    quickActions: false
                }
            },
            {
                path: '**',
                redirectTo: 'activity-feed'
            }
        ],
        data: {
            headerColor: 'blue'
        },
        canActivate: [
            () => {
                const parentGuard = inject(ParentPersonalizationGuard)
                const rootGuard = inject(RootGuard)
                return parentGuard.canActivate().pipe(switchMap(() => rootGuard.canActivate()))
            },
            () => inject(ShellGuard).canActivate(),
            (route: ActivatedRouteSnapshot) => inject(HeaderGuard).canActivate(route)
        ]
    },
    {
        path: 'center-info/:id',
        loadChildren: () => import('./modules/center-info.module').then((m) => m.CenterInfoModule),
        data: {
            headerColor: 'white'
        },
        canActivate: [(route: ActivatedRouteSnapshot) => inject(HeaderGuard).canActivate(route)]
    },
    {
        path: 'pdf-viewer/:url/:title/:filename',
        loadChildren: () => import('./modules/pdf-viewer.module').then((m) => m.PdfViewModule),
        data: {
            headerColor: 'white'
        },
        canActivate: [(route: ActivatedRouteSnapshot) => inject(HeaderGuard).canActivate(route)]
    },
    {
        path: 'arrival-notes',
        loadChildren: () =>
            import('./modules/arrival-notes.module').then((m) => m.ArrivalNotesModule),
        canActivate: [(route: ActivatedRouteSnapshot) => inject(HeaderGuard).canActivate(route)],
        data: { headerColor: 'white' }
    },
    {
        path: 'messages',
        loadChildren: () => import('./modules/messages.module').then((m) => m.MessagesModule),
        canActivate: [(route: ActivatedRouteSnapshot) => inject(HeaderGuard).canActivate(route)],
        data: { headerColor: 'blue' }
    },
    {
        path: 'settings',
        loadChildren: () => import('./modules/settings.module').then((m) => m.SettingsModule),
        canActivate: [(route: ActivatedRouteSnapshot) => inject(HeaderGuard).canActivate(route)],
        data: { headerColor: 'white' }
    },
    {
        path: 'developmental-observations',
        loadChildren: () =>
            import('./modules/developmental-observation.module').then(
                (m) => m.DevelopmentalObservationModule
            ),
        canActivate: [(route: ActivatedRouteSnapshot) => inject(HeaderGuard).canActivate(route)],
        data: { headerColor: 'white' }
    },
    {
        path: 'journey-portfolios',
        loadChildren: () =>
            import('./modules/journey-portfolios.module').then((m) => m.JourneyPortfoliosModule),
        canActivate: [(route: ActivatedRouteSnapshot) => inject(HeaderGuard).canActivate(route)],
        data: { headerColor: 'white' }
    },
    {
        path: 'assessment-planning',
        loadChildren: () =>
            import('./modules/assessment-planning.module').then((m) => m.AssessmentPlanningModule),
        canActivate: [(route: ActivatedRouteSnapshot) => inject(HeaderGuard).canActivate(route)],
        data: { headerColor: 'white' }
    },
    {
        path: 'curriculum-plans',
        loadChildren: () =>
            import('./modules/curriculum-plans.module').then((m) => m.CurriculumPlansModule),
        canActivate: [(route: ActivatedRouteSnapshot) => inject(HeaderGuard).canActivate(route)],
        data: { headerColor: 'white' }
    },
    {
        path: '**',
        redirectTo: 'login'
    }
]

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            onSameUrlNavigation: 'reload',
            scrollPositionRestoration: 'enabled',
            paramsInheritanceStrategy: 'always',
            preloadingStrategy: PreloadAllModules
        })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {}
