import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { FullstoryService } from '@bh/logging'
import { FeatureService, logoutSuccess } from '@bh/security'
import { CapacitorJailbreakRootDetection } from '@evehr/capacitor-jailbreak-root-detection'
import { BiometricService, Profile, selectProfile } from '@events'
import { AlertController } from '@ionic/angular'
import { Store } from '@ngrx/store'
import { TranslateService } from '@ngx-translate/core'
import { combineLatest, from, Observable, of } from 'rxjs'
import { filter, map, switchMap, take, tap } from 'rxjs/operators'

@Injectable({
    providedIn: 'root'
})
export class RootGuard {
    constructor(
        protected readonly featureService: FeatureService,
        protected readonly store: Store,
        private readonly fullStoryService: FullstoryService,
        private readonly translate: TranslateService,
        private readonly alertController: AlertController,
        private readonly biometricService: BiometricService,
        private readonly router: Router
    ) {}

    public canActivate(): Observable<boolean> {
        const profile$ = this.store.select(selectProfile)
        return combineLatest([profile$]).pipe(
            filter(([profile]) => !!profile),
            switchMap(([profile]) => this.checkIfDeviceIsRooted(profile))
        )
    }

    private checkIfDeviceIsRooted(profile: Profile | undefined): Observable<boolean> {
        return from(CapacitorJailbreakRootDetection.isJailbrokenOrRooted()).pipe(
            take(1),
            switchMap(({ result }) => this.determineLoginAction(result, profile))
        )
    }

    private determineLoginAction(
        isRooted: boolean,
        profile: Profile | undefined
    ): Observable<boolean> {
        if (this.isLoginAllowed() && isRooted) {
            return this.handleLoginWithRootedDevice(profile)
        } else {
            return this.handleDisallowedLogin()
        }
    }

    private handleLoginWithRootedDevice(profile: Profile | undefined): Observable<boolean> {
        return this.handleRootedDevice(profile)
    }

    private handleDisallowedLogin(): Observable<boolean> {
        return this.allowLogin()
    }

    private isLoginAllowed() {
        const shouldPreventRootedLogin = this.featureService.getFeatureValue<boolean>(
            'mbd.parent.block-root',
            false
        )
        return shouldPreventRootedLogin
    }

    private allowLogin(): Observable<boolean> {
        return of(true)
    }

    private handleRootedDevice(attributes: any): Observable<boolean> {
        this.fullStoryService.sendEvent('using-rooted-device', { guardianId: attributes.id })
        return this.presentRootedDialog()
    }

    presentRootedDialog(): Observable<boolean> {
        const translations = ['rooted-alert.message', 'rooted-alert.ok-button']
        return from(this.translate.get(translations)).pipe(
            take(1),
            switchMap(({ 'rooted-alert.message': message, 'rooted-alert.ok-button': okButton }) => {
                return from(
                    this.alertController.create({
                        id: 'rootedAlertDialog',
                        cssClass: '',
                        subHeader: '',
                        backdropDismiss: false,
                        message, // Use the translated message
                        buttons: [
                            {
                                text: okButton, // Use the translated button text
                                role: 'close',
                                cssClass: 'confirmation-alert'
                            }
                        ]
                    })
                ).pipe(
                    take(1),
                    tap((alert) => {
                        alert.present()
                        alert.onDidDismiss().then(() => {
                            this.store.dispatch(logoutSuccess())
                            this.biometricService.getBiometricsResult()
                            this.router.navigate(['/login'])
                        })
                    }),
                    map(() => false)
                )
            })
        )
    }
}
