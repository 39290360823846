<div *ngIf="expandable; else noExpand">
    <bh-text
        [text]="
            !!(isExpandAll$ | async)
                ? ('activity-feed.expand' | translate)
                : ('activity-feed.collapse' | translate)
        "
        size="small"
        (click)="updateExpand()"
    ></bh-text>
    <bh-accordion
        [accordionGroup]="accordionGroupId"
        *ngIf="activityFeed.attendanceItems.length > 0"
        [title]="'arrival.title' | translate"
        color="black"
        type="card"
        image="assets/arrival-pickup.svg"
    >
        <ng-container content *ngFor="let act of activityFeed.attendanceItems">
            <bh-activity-card [dependentId]="dependentId" [activity]="act"></bh-activity-card>
        </ng-container>
    </bh-accordion>
    <bh-accordion
        [accordionGroup]="accordionGroupId"
        *ngIf="activityFeed.groupedNotes.length > 0"
        image="assets/teacher-notes.svg"
        [title]="'activity-feed.notes' | translate"
        color="black"
        type="card"
    >
        <ng-container content *ngFor="let act of activityFeed.groupedNotes">
            <ng-container *ngIf="$any(act).type !== 'question'">
                <bh-activity-card [dependentId]="dependentId" [activity]="act"></bh-activity-card>
            </ng-container>
        </ng-container>
    </bh-accordion>
    <bh-accordion
        [accordionGroup]="accordionGroupId"
        *ngIf="activityFeed.bathrooms.length > 0"
        [title]="'home.bathroom' | translate"
        color="black"
        type="card"
        image="assets/Diaper.svg"
    >
        <ng-container content *ngFor="let act of activityFeed.bathrooms">
            <bh-activity-card [dependentId]="dependentId" [activity]="act"></bh-activity-card>
        </ng-container>
    </bh-accordion>
    <bh-accordion
        [accordionGroup]="accordionGroupId"
        *ngIf="activityFeed.meals.length > 0"
        [title]="'home.meals' | translate"
        color="black"
        type="card"
        image="assets/Meal.svg"
    >
        <ng-container content *ngFor="let act of activityFeed.meals">
            <bh-activity-card [dependentId]="dependentId" [activity]="act"></bh-activity-card>
        </ng-container>
    </bh-accordion>
    <bh-accordion
        [accordionGroup]="accordionGroupId"
        *ngIf="activityFeed.medications.length > 0 || activityFeed.medicationEntries.length > 0"
        [title]="'home.medicine' | translate"
        color="black"
        type="card"
        image="assets/medicine-plus.svg"
    >
        <ng-container content>
            <ng-container *ngIf="activityFeed.medications.length > 0">
                <ng-container *ngFor="let act of activityFeed.medications">
                    <bh-activity-card
                        [dependentId]="dependentId"
                        [activity]="act"
                    ></bh-activity-card>
                </ng-container>
            </ng-container>
            <ng-container *ngIf="activityFeed.medicationEntries.length > 0">
                <ng-container *ngFor="let act of activityFeed.medicationEntries">
                    <bh-activity-card
                        [dependentId]="dependentId"
                        [activity]="act"
                    ></bh-activity-card>
                </ng-container>
            </ng-container>
        </ng-container>
    </bh-accordion>
    <bh-accordion
        [accordionGroup]="accordionGroupId"
        *ngIf="activityFeed.naps.length > 0"
        [title]="'home.sleep' | translate"
        color="black"
        type="card"
        image="assets/nap.svg"
    >
        <ng-container content *ngFor="let act of activityFeed.naps">
            <bh-activity-card [dependentId]="dependentId" [activity]="act"></bh-activity-card>
        </ng-container>
    </bh-accordion>
    <bh-accordion
        [accordionGroup]="accordionGroupId"
        *ngIf="activityFeed.observations.length > 0"
        [title]="'home.developmental-observation' | translate"
        color="black"
        type="card"
        image="assets/boxes.svg"
    >
        <ng-container content *ngFor="let act of activityFeed.observations">
            <bh-activity-card [dependentId]="dependentId" [activity]="act"></bh-activity-card>
        </ng-container>
    </bh-accordion>
    <bh-accordion
        [accordionGroup]="accordionGroupId"
        *ngIf="activityFeed.activities.length > 0"
        [title]="'home.witwht' | translate"
        color="black"
        type="card"
        image="assets/art-smart.svg"
    >
        <ng-container content *ngFor="let act of activityFeed.activities">
            <bh-activity-card
                [dependentId]="dependentId"
                [activity]="act"
                [dailyReportView]="dailyReportView"
            ></bh-activity-card>
        </ng-container>
    </bh-accordion>
    <bh-accordion
        [accordionGroup]="accordionGroupId"
        *ngIf="activityFeed.snapshots.length > 0"
        [title]="'home.snapshot' | translate"
        color="black"
        type="card"
        image="assets/snapshot.svg"
    >
        <ng-container content *ngFor="let act of activityFeed.snapshots">
            <bh-activity-card [dependentId]="dependentId" [activity]="act"></bh-activity-card>
        </ng-container>
    </bh-accordion>
    <bh-accordion
        [accordionGroup]="accordionGroupId"
        image="assets/learning-at-home.svg"
        *ngIf="activityFeed.learningAtHomes.length > 0"
        [title]="'activity-feed.learning' | translate"
        color="black"
        type="card"
    >
        <ng-container content *ngFor="let act of activityFeed.learningAtHomes">
            <bh-activity-card [dependentId]="dependentId" [activity]="act"></bh-activity-card>
        </ng-container>
    </bh-accordion>
</div>

<ng-template #noExpand>
    <div [ngClass]="dailyReportView ? 'daily-report-view' : 'section'">
        <bh-heading
            size="medium"
            *ngIf="activityFeed.attendanceItems && activityFeed.attendanceItems.length > 0"
            [text]="'arrival.title' | translate">
            <img
                id="heading"
                start
                *ngIf="dailyReportView"
                src="assets/arrival-pickup.svg"
                alt="lah-icon"
            />
        </bh-heading>
        <ng-container content *ngFor="let act of activityFeed.attendanceItems">
            <bh-activity-card [dependentId]="dependentId" [activity]="act"></bh-activity-card>
        </ng-container>
    </div>

    <div [ngClass]="dailyReportView ? 'daily-report-view' : 'section'">
        <bh-heading
            size="medium"
            *ngIf="
                (activityFeed.teacherNotes && activityFeed.teacherNotes.length > 0)
            "
            [text]="'activity-feed.notes' | translate">
            <img
                id="heading"
                start
                *ngIf="dailyReportView"
                src="assets/teacher-notes.svg"
                alt="notes-icon"
            />
        </bh-heading>
        <ng-container content>
            <ng-container *ngIf="activityFeed.teacherNotes && activityFeed.teacherNotes.length > 0">
                <ng-container *ngFor="let act of activityFeed.teacherNotes">
                    <bh-activity-card
                        [dependentId]="dependentId"
                        [activity]="act"
                    ></bh-activity-card>
                </ng-container>
            </ng-container>
        </ng-container>
    </div>

    <div [ngClass]="dailyReportView ? 'daily-report-view' : 'section'">
        <bh-heading
            size="medium"
            *ngIf="activityFeed.bathrooms && activityFeed.bathrooms.length > 0"
            [text]="'home.bathroom' | translate"
        >
            <img
                id="heading"
                start
                *ngIf="dailyReportView"
                src="assets/Diaper.svg"
                alt="art-icon"
            />
        </bh-heading>
        <ng-container content *ngFor="let act of activityFeed.bathrooms">
            <bh-activity-card [dependentId]="dependentId" [activity]="act"></bh-activity-card>
        </ng-container>
    </div>

    <div [ngClass]="dailyReportView ? 'daily-report-view' : 'section'">
        <bh-heading
            size="medium"
            *ngIf="activityFeed.meals && activityFeed.meals.length > 0"
            [text]="'home.meals' | translate"
        >
            <img id="heading" start *ngIf="dailyReportView" src="assets/Meal.svg" alt="meal-icon" />
        </bh-heading>
        <ng-container content *ngFor="let act of activityFeed.meals">
            <bh-activity-card [dependentId]="dependentId" [activity]="act"></bh-activity-card>
        </ng-container>
    </div>
    <div [ngClass]="dailyReportView ? 'daily-report-view' : 'section'">
        <bh-heading
            size="medium"
            *ngIf="activityFeed.medications?.length || activityFeed.medicationEntries?.length"
            [text]="'home.medicine' | translate"
        >
            <img
                id="heading"
                start
                *ngIf="dailyReportView"
                src="assets/medicine.svg"
                alt="art-icon"
            />
        </bh-heading>
        <ng-container content *ngFor="let act of activityFeed.medications">
            <bh-activity-card [dependentId]="dependentId" [activity]="act"></bh-activity-card>
        </ng-container>
        <ng-container content *ngFor="let act of activityFeed.medicationEntries">
            <bh-activity-card [dependentId]="dependentId" [activity]="act"></bh-activity-card>
        </ng-container>
    </div>

    <div [ngClass]="dailyReportView ? 'daily-report-view' : 'section'">
        <bh-heading
            size="medium"
            *ngIf="activityFeed.naps && activityFeed.naps.length > 0"
            [text]="'home.sleep' | translate">
            <img
                id="heading"
                start
                *ngIf="dailyReportView"
                src="assets/nap.svg"
                alt="nap-icon"
            />
        </bh-heading>
        <ng-container content *ngFor="let act of activityFeed.naps">
            <bh-activity-card [dependentId]="dependentId" [activity]="act"></bh-activity-card>
        </ng-container>
    </div>

    <div [ngClass]="dailyReportView ? 'daily-report-view' : 'section'">
        <bh-heading
            size="medium"
            *ngIf="activityFeed.observations && activityFeed.observations.length > 0"
            [text]="'home.developmental-observation' | translate">
            <img
                id="heading"
                start
                *ngIf="dailyReportView"
                src="assets/boxes.svg"
                alt="observation-icon"
            />
        </bh-heading>
        <ng-container content *ngFor="let act of activityFeed.observations">
            <bh-activity-card [dependentId]="dependentId" [activity]="act" [showReaction]="showReaction"></bh-activity-card>
        </ng-container>
    </div>

    <div [ngClass]="dailyReportView ? 'daily-report-view' : 'section'">
        <bh-heading
            size="regular"
            *ngIf="activityFeed.activities && activityFeed.activities.length > 0"
            [text]="'home.witwht' | translate">
            <img
                id="heading"
                start
                *ngIf="dailyReportView"
                src="assets/art-smart.svg"
                alt="art-icon"
            />
        </bh-heading>
        <ng-container content *ngFor="let act of activityFeed.activities">
            <bh-activity-card
                [dependentId]="dependentId"
                [activity]="act"
                [dailyReportView]="dailyReportView"
                [showReaction]="showReaction"
            ></bh-activity-card>
        </ng-container>
    </div>

    <div [ngClass]="dailyReportView ? 'daily-report-view' : 'section'">
        <bh-heading
            size="medium"
            *ngIf="activityFeed.snapshots && activityFeed.snapshots.length > 0"
            [text]="'activity-feed.snapshots' | translate">
            <img
                id="heading"
                start
                *ngIf="dailyReportView"
                src="assets/snapshot.svg"
                alt="lah-icon"
            />
        </bh-heading>
        <ng-container content *ngFor="let act of activityFeed.snapshots">
            <bh-activity-card [dependentId]="dependentId" [activity]="act" [showReaction]="showReaction"></bh-activity-card>
        </ng-container>
    </div>

    <div [ngClass]="dailyReportView ? 'daily-report-view' : 'section'">
        <bh-heading
            size="medium"
            *ngIf="activityFeed.learningAtHomes && activityFeed.learningAtHomes.length > 0"
            [text]="'activity-feed.learning' | translate">
            <img
                id="heading"
                start
                *ngIf="dailyReportView"
                src="assets/learning-at-home.svg"
                alt="lah-icon"
            />
        </bh-heading>
        <ng-container content *ngFor="let act of activityFeed.learningAtHomes">
            <bh-activity-card [dependentId]="dependentId" [activity]="act" [showReaction]="showReaction"></bh-activity-card>
        </ng-container>
    </div>

    <div [ngClass]="'daily-report-view' + ' ' + 'card-component-even' " *ngIf="activityFeed.classroomActivity && activityFeed.classroomActivity.length > 0">
        <bh-heading 
            class="headerPadding"
            size="medium" 
            [text]="'calendar.classroom-activities' | translate">
            <img id="heading" start *ngIf="dailyReportView" src="assets/Classroom.svg" alt="lah-icon" />
        </bh-heading>
        <ng-container content *ngFor="let classroomActivity of activityFeed.classroomActivity">
            <bh-activity-card [dependentId]="dependentId" [calenderFeed]="classroomActivity" class="cardborderbottom" [cardBackgroundClass]="'even'"></bh-activity-card>
        </ng-container>
    </div>

    <div [ngClass]="'daily-report-view' + ' ' + 'card-component-even'" *ngIf="activityFeed.dueDate && activityFeed.dueDate.length > 0">
        <bh-heading 
            class="headerPadding"
            size="medium" 
            [text]="'calendar.due' | translate">
            <img id="heading" start *ngIf="dailyReportView" src="assets/Classroom.svg" alt="lah-icon" />
        </bh-heading>
        <ng-container content *ngFor="let dueDate of activityFeed.dueDate">
            <bh-activity-card [dependentId]="dependentId" [calenderFeed]="dueDate" class="cardborderbottom" [cardBackgroundClass]="'even'"></bh-activity-card>
        </ng-container>
    </div>

    
    <div [ngClass]="'daily-report-view' + ' ' + 'card-component-odd'" *ngIf="activityFeed.centerAnnouncement && activityFeed.centerAnnouncement.length > 0">
        <bh-heading 
            class="headerPadding"
            size="medium" 
            [text]="'calendar.center-announcement' | translate">
            <img id="heading" start *ngIf="dailyReportView" src="assets/Announcement.svg" alt="lah-icon" />
        </bh-heading>
        <ng-container content *ngFor="let centerAnnouncement of activityFeed.centerAnnouncement">
            <bh-activity-card [dependentId]="dependentId" [calenderFeed]="centerAnnouncement" class="cardborderbottom" [cardBackgroundClass]="'odd'"></bh-activity-card>
        </ng-container>
    </div>
</ng-template>
