import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { environment } from '../../../../app/src/environments/environment'
import { Shareability } from '../models/shareability'

@Injectable({
    providedIn: 'root'
})
export class ShareabilityService {
    constructor(private httpClient: HttpClient) {}

    public getSnapshotShareability(
        guardianId: string,
        snapshotId: string
    ): Observable<Shareability> {
        return this.httpClient
            .get(
                `${
                    environment.config().security.backendHost
                }/guardian/${guardianId}/shareability/snapshot/${snapshotId}`
            )
            .pipe(map((data: any) => new Shareability(guardianId, snapshotId, data)))
    }

    public getObservationShareability(
        guardianId: string,
        snapshotId: string
    ): Observable<Shareability> {
        return this.httpClient
            .get(
                `${
                    environment.config().security.backendHost
                }/guardian/${guardianId}/shareability/observation/${snapshotId}`
            )
            .pipe(map((data: any) => new Shareability(guardianId, snapshotId, data)))
    }
}
