export class Profile {
    id: string
    firstName: string
    lastName: string
    creationDate?: Date
    updateDate?: Date
    backupEligible: boolean
    collgeCoachEligible: boolean
    edAssistEligible: boolean
    elderCareEligible: boolean
    familySupportEligible: boolean
    clientId: string
    brightStarId: string
    isParentOnlyBUC: boolean
    email: string
    phoneNumber: string

    constructor(data: any) {
        this.id = data.id
        this.firstName = data.first_name
        this.lastName = data.last_name
        this.creationDate = data.created && new Date(data.created)
        this.updateDate = data.updated && new Date(data.updated)
        this.backupEligible = data.eligible_for_backup
        this.collgeCoachEligible = data.eligible_for_collegecoach
        this.edAssistEligible = data.eligible_for_edassist
        this.elderCareEligible = data.eligible_for_eldercare
        this.familySupportEligible = data.eligible_for_familysupport
        this.clientId = data.client_id
        this.brightStarId = data.brightstar_id
        this.isParentOnlyBUC = data.is_backup_guardian
        this.email = data.email
        this.phoneNumber =
            data.phone_numbers?.mobile ?? data.phone_numbers?.home ?? data.phone_numbers?.work ?? ''
    }
}

export enum ParentPersistentKeys {
    biometricsEnabled = 'biometricsEnabled',
    storedAuthUser = 'authUser',
    storedAuthPass = 'authPass',
    loginStatus = 'loginStatus',
    consentBanner = 'consentBanner'
}
